<template>
    <wait-for-resource
        :resource="user"
        class="h-100"
    >
        <router-view :user="user" />
    </wait-for-resource>
</template>

<script>
import WaitForResource from '@/components/common/WaitForResource';
import {User} from '@/models/User';

/**
 * A customized router view that fetches the user by id, waits for the user to load and pass it to child routes.
 */
export default {
    name: 'UserRouterView',
    components: {WaitForResource},
    props: {
        /**
         * User identifier passed by route.
         */
        id: {
            type: [String, Number],
            required: true,
        },
    },
    data: function() {
        return {
            /**
             * The user to fetch.
             */
            user: new User({id: this.id}),
        };
    },
    watch: {
        id: {
            handler: async function() {
                this.user = new User({id: this.id});
                await this.user.fetch();
            },
            immediate: true,
        },
    },
};
</script>
